//import json from "@/assets/data.json";


import { onMounted, onUnmounted, ref, computed, watchEffect } from "vue";
import { useStore } from "vuex";
import { SERVER_URL } from "../constants";

function changed(value, min, max) {
    return value < min || value > max;
}

export default (props) => {
    function getWindowWidth() {
        return window.innerWidth * window.devicePixelRatio;
    }

    function getWindowHeight() {
        return window.innerHeight * window.devicePixelRatio;
    }

    const store = useStore();
    const windowWidth = ref(getWindowWidth());
    const windowHeight = ref(getWindowHeight());
    var finalWidth = 0;
    var finalHeight = 0;
    const ratioWidth = ref(1);
    const ratioHeight = ref(1);

    watchEffect(() => {
        ratioWidth.value = store.state.mq.vw / 100;
        ratioHeight.value = store.state.mq.vh / 100;
    })

    function getImage(src, crop = true, ratio = 1) {
        return SERVER_URL + "/image/" + src + "?width=" + windowWidth.value * ratio * ratioWidth.value + "&height=" + windowHeight.value * ratio * ratioHeight.value + "&crop=" + crop;
    }

    let exactness = props?.exactness ?? null;

    function updateAnimation() {
        let offset = 15 * window.devicePixelRatio;

        let width = windowWidth.value
        let height = windowHeight.value

        if (width < finalWidth) {
            width += offset;
            if (width > finalWidth) {
                width = finalWidth;
            }
        } else if (width > finalWidth) {
            width -= offset;
            if (width < finalWidth) {
                width = finalWidth;
            }
        }
        if (height < finalHeight) {
            height += offset;
            if (height > finalHeight) {
                height = finalHeight;
            }
        } else if (height > finalHeight) {
            height -= offset;
            if (height < finalHeight) {
                height = finalHeight;
            }
        }

        windowWidth.value = width;
        windowHeight.value = height;

        if (finalWidth != width || finalHeight != height) {
            window.requestAnimationFrame(updateAnimation);
        }
    }

    function resizeListener() {
        if (!exactness) {
            finalWidth = getWindowWidth();
            finalHeight = getWindowHeight();
            window.requestAnimationFrame(updateAnimation)
        } else {
            let newWidth = getWindowWidth();
            let newHeight = getWindowHeight();

            let changedWidthRatio = newWidth / windowWidth.value;
            let changedHeightRatio = newHeight / windowHeight.value;

            let ratioChanged = newWidth > newHeight && windowWidth.value < windowHeight.value;

            let lower = 1 - exactness;
            let upper = 1 + exactness;

            if (ratioChanged || changed(changedWidthRatio, lower, upper) || changed(changedHeightRatio, lower, upper)) {
                windowWidth.value = newWidth;
                windowHeight.value = newHeight;
            }
        }
    }

    function resizeListener2() {
        setTimeout(resizeListener, 500);
    }

    onMounted(() => {
        window.addEventListener("resize", resizeListener);
        window.addEventListener("orientationchange", resizeListener2);
    })
    onUnmounted(() => {
        window.removeEventListener("resize", resizeListener);
        window.removeEventListener("orientationchange", resizeListener2);
    })
    return {
        getImage: getImage,
        dimensions: computed(() => {
            return { width: windowWidth.value * ratioWidth.value, height: windowHeight.value * ratioHeight.value };
        })
    };
};
