<template>
  <div :key="src">
    <video :src="src" :style="style" :poster="poster" controls />
  </div>
</template>
<script>
import { computed } from "vue";
import { SERVER_URL } from "../../constants";
export default {
  name: "VideoComponent",
  props: {
    content: Object,
    context: Object,
  },
  setup(props) {
    const poster =
      props.content.poster != null
        ? SERVER_URL +
          "/image/" +
          props.content.poster +
          "?width=1920&height=1080&crop=true"
        : "";

    return {
      SERVER_URL,
      props,
      poster,
      src: computed(() => {
        return SERVER_URL + "/" + props.context.getText(props.content);
      }),
      style: computed(() => {
        return props.context.getStyle(props.content.style);
      }),
    };
  },
};
</script>
<style scoped>
.box {
  position: relative;
  height: 400px;
  width: 380px;
  display: block;
  background: linear-gradient(0deg, black, #444444);
}

.glowing::before {
  content: "";
  position: absolute;
  left: -2px;
  top: -2px;
  background: linear-gradient(
    45deg,
    #e8f74d,
    #ff6600d9,
    #00ff66,
    #13ff13,
    #ad27ad,
    #bd2681,
    #6512b9,
    #ff3300de,
    #5aabde
  );
  background-size: 400%;
  width: calc(100% + 5px);
  height: calc(100% + 5px);
  z-index: -1;
  animation: glower 20s linear infinite;
}

@keyframes glower {
  0% {
    background-position: 0 0;
  }

  50% {
    background-position: 400% 0;
  }

  100% {
    background-position: 0 0;
  }
}
</style>