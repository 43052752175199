<template>
  <div :key="src">
    <audio :src="src" :style="style" controls />
  </div>
</template>
<script>
import { computed } from "vue";
import { SERVER_URL } from "../../constants";
export default {
  name: "AudioComponent",
  props: {
    content: Object,
    context: Object,
  },
  setup(props) {
    return {
      SERVER_URL,
      props,
      src: computed(() => {
        return SERVER_URL + "/" + props.context.getText(props.content);
      }),
      style: computed(() => {
        return props.context.getStyle(props.content.style);
      }),
    };
  },
};
</script>
<style scoped>
</style>