<template>
  <div
    @mouseover="hover = true"
    @mouseleave="hover = false"
    active="true"
    v-html="text"
    :style="style"
    style="display: inline-block"
  ></div>
</template>
<script>
import { computed } from "vue";
import useStyle from "@/modules/style.js";
export default {
  name: "TextComponent",
  props: {
    content: Object,
    context: Object,
  },
  setup(props) {
    const { hover, style } = useStyle(props);
    return {
      hover,
      style,
      props,
      text: computed(() => {
        return props.context.getText(props.content);
      }),
    };
  },
};
</script>
<style scoped>
</style>