<template>
  <header ref="container" :style="headerStyle">
    <div @click="closeMenu">
      <ContentVue :context="context" :content="content.title" />
      <Menue v-if="!portrait" />
      <ContentVue v-else @click.stop="() => props.showMenu(true)" :context="context" :content="content.button" />
    </div>
  </header>
</template>
<script setup lang="ts">
import Menue from "@/components/Menue.vue";
import { useStore } from "vuex";
import { computed } from "vue";
import ContentVue from "./dynamic/ContentComponent.vue";
import Image from "@/modules/image.js";
import ResizeListener from "@/modules/resizelistener.js";

const props = defineProps<{
  showMenu: Function,
  content: any,
  context: any,
  heightCallback: Function,
}>()

const store = useStore();
const { dimensions } = Image();
const { container } = ResizeListener({ ...props, tag: "header" });

const headerStyle = computed(() => {
  return {
    left: store.state.mq.vwCenter + "vw",
    ...props.context.getStyle2(props.content.style),
  };
})
const portrait = computed(() => {
  if (store.state.mq.type == "mobile") {
    return true;
  }
  return dimensions.value.width < dimensions.value.height;
})
</script>
<style scoped></style>