<template>
  <div v-if="content && data.settings.header && data.settings.footer">
    <HeaderVue :style="headerStyle" :heightCallback="(height, margin) => {
      $store.state.headerHeight = height + margin;
    }
      " :showMenu="showMenu" :content="data.settings.header" :context="data" />
    <transition name="fade">
      <ContentVue :content="content.background" :context="data" :key="$route.fullPath" />
    </transition>
    <MenuePopup :show="portrait && isMenuShown" :close="() => showMenu(false)" />
    <div v-if="activateGaps" :style="{ height: $store.state.headerHeight + 'px' }" />
    <transition name="fade" mode="out-in">
      <div :key="$route.fullPath" class="content">
        <ContentVue :style="contentStyle" :content="content.content" :context="data" />
      </div>
    </transition>
    <div v-if="content.showFooter" :style="{ height: $store.state.footerHeight + 'px' }" />
    <FooterVue v-if="content.showFooter" :heightCallback="(height, margin) => {
      $store.state.footerHeight = height + margin;
    }
      " :content="data.settings.footer" :context="data" />
  </div>
</template>

<script>
import Data from "@/modules/data.js";

import ContentVue from "@/components/dynamic/ContentComponent.vue";
import HeaderVue from "@/components/HeaderComponent.vue";
import FooterVue from "@/components/FooterComponent.vue";
import { useRoute } from "vue-router";
import { ref, computed, watchEffect } from "vue";
import MenuePopup from "@/components/MenuePopupComponent.vue";
import Image from "@/modules/image.js";
import { useStore } from "vuex";

export default {
  name: "HomeComponent",
  components: { HeaderVue, FooterVue, ContentVue, MenuePopup },

  setup() {
    const store = useStore();
    const isMenuShown = ref(false);
    function showMenu(show) {
      isMenuShown.value = show;
    }
    const route = useRoute();
    const { data } = Data();
    const { dimensions } = Image();

    //const pages = data.pages;
    var routePath = route.params.path ?? "/";
    if (!(data.value.pages ?? {})[routePath]) {
      routePath = "/";
    }

    const content = computed(() => {
      var routePath = route.params.path ?? "/";
      if (!(data.value.pages ?? {})[routePath]) {
        routePath = "/";
      }
      return (data.value.pages ?? {})[routePath ?? "/"];
    });

    watchEffect(() => {
      if (!content.value) {
        return;
      }
      let height = store.state.headerHeight;
      if (content.value.showFooter) {
        height += store.state.footerHeight;
      }
      store.state.contentHeight = (dimensions.value.height / window.devicePixelRatio) - height - 10;
    });

    const portrait = computed(() => {
      if (store.state.mq.type == "mobile") {
        return true;
      }
      return dimensions.value.width < dimensions.value.height;
    })

    return {
      portrait,
      activateGaps: computed(
        () =>
          data.value.getStyle2(data.value.settings.header.style).position ==
          "fixed"
      ),
      contentStyle: computed(() => content.value.fixedHeight
        ? { height: store.state.contentHeight + "px" }
        : {}
      ),
      headerStyle: computed(() =>
        !store.state.admin
          ? {
            position: "relative",
            "z-index": "15",
            transform: "translateX(-50%)",
          }
          : {}
      ),
      dimensions,
      routePath,
      isMenuShown,
      route,
      data,
      content,
      showMenu,
    };
  },
};
</script>
<style scoped>
.content {
  z-index: 10;
  position: relative;
}
</style>
