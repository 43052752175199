<template>
  <transition name="fade">
    <div v-if="isShown" @click.stop="props.close" class="popup" :style="popupStyle">
      <div @click.stop="" :style="style">
        <slot />
      </div>
    </div>
  </transition>
</template>
<script setup lang="ts">
import { computed, toRef } from "vue";
import { useStore } from "vuex";
const props = defineProps<{
  show: Boolean,
  close: Function,
  style: any,
}>()
const isShown = toRef(props, "show");
const store = useStore();
const popupStyle = computed(() => {
  return {
    left: store.state.mq.vwCenter + "vw",
    width: store.state.mq.vw + "vw",
    height: store.state.mq.vh + "vh",
  };
})
</script>
<style scoped>
.popup {
  transform: translate(-50%, 0);
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 99;
  background-color: rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
