<template>
  <table :style="props.context.getStyle2(props.content.style)">
    <tr v-for="row in props.content.elements" :key="row" :style="props.context.getStyle2(props.content.style_tr)">
      <td v-for="col in row" :key="col" :style="{
        ...props.context.getStyle2(props.content.style_td),
        ...props.context.getStyle2(col.style),
      }">
        <TextVue :content="col" :context="props.context" />
      </td>
    </tr>
  </table>
</template>
<script>
import { computed } from "vue";
import TextVue from "@/components/dynamic/Text.vue";
export default {
  name: "TableComponent",
  props: {
    content: Object,
    context: Object,
  },
  components: { TextVue },
  setup(props) {
    return {
      props,
      elements: computed(() => {
        return props.context.getText(props.content.text);
      }),
    };
  },
};
</script>
<style scoped></style>