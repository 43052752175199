<template>
  <transition name="fade">
    <footer v-if="true" :style="footerStyle" ref="container">
      <styled-text text="Deutsch" :context="context" @click="changeLanguage('de')" v-if="lang == 'en'"
        :content="content.text" />
      <styled-text text="English" :context="context" @click="changeLanguage('en')" v-else-if="lang == 'de'"
        :content="content.text" />
      <styled-text text="&#8226;" :context="context" :content="content.text"
        style="margin-left: 20px; margin-right: 20px" />
      <styled-text text="Imprint" :context="context" v-if="lang == 'en'" :content="content.text"
        @click="$router.push('/impress')" />
      <styled-text text="Impressum" :context="context" v-else-if="lang == 'de'" :content="content.text"
        @click="$router.push('/impress')" />
      <img src="@/images/f_logo_RGB-White_100.png" class="logo" style="margin-right: 54px"
        @click="open('https://www.facebook.com/kira.koppandi')" />
      <img src="@/images/glyph-logo_May2016.png" class="logo" style="margin-right: 12px"
        @click="open('https://www.instagram.com/kira_koppandi/')" />
    </footer>
  </transition>
</template>
<script setup lang="ts">
import { computed } from "vue";
import { useStore } from "vuex";
import ResizeListener from "@/modules/resizelistener.js";
import StyledText from "./dynamic/StyledText.vue";


const props = defineProps<{
  heightCallback: Function,
  content: any,
  context: any,
}>()

const { container } = ResizeListener({ ...props, tag: "footer" });

const store = useStore();

function changeLanguage(lang: string) {
  store.commit("changeLanguage", lang);
}

const lang = computed(() => {
  return store.state.language;
})

const open = (url: string) => {
  window.open(url);
}

const footerStyle = computed(() => {
  if (store.state.mq.vh != 100) {
    return {
      ...props.context.getStyle2(props.content.style),
      bottom: 100 - store.state.mq.vh + "vh",
    };
  }
  return props.context.getStyle2(props.content.style);
})
</script>
<style scoped>
.logo {
  width: 24px;
  height: 24px;
  padding: 4px;
  margin-left: 12px;
  position: absolute;
  top: 50%;
  right: 0;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.logo:hover {
  filter: opacity(50%);
}

.logo:active {
  filter: opacity(50%);
}
</style>