<template>
  <div />
</template>
<script>
import { useStore } from "vuex";
import router from "../router";
export default {
  setup() {
    const store = useStore();
    store.commit("admin", true);
    store.commit("viewport", { vw: 50, vh: 50, ratio: 0.5, type: "desktop" });
    router.replace("/");
    return {};
  },
};
</script>
