import { onMounted, onUpdated, ref } from "vue";

export default (props) => {
    const container = ref(null);

    let height = 0;
    let margin = 0;

    function updateHeight() {
        if (container.value != null && props.heightCallback != null) {
            let newHeight = container.value.clientHeight;
            let style = window.getComputedStyle(container.value);
            let newMargin = parseInt(style.marginTop) + parseInt(style.marginBottom);
            //console.log("Margin: " + newMargin);
            if (newHeight != height || newMargin != margin) {
                //console.log("[" + props.tag + "] New height [" + caller + "]: " + height + " -> " + newHeight);
                props.heightCallback(newHeight, newMargin);
                height = newHeight;
                margin = newMargin;
            }
        }
    }

    onMounted(() => {
        updateHeight("onMounted");
    });

    onUpdated(() => {
        updateHeight("onUpdated");
    });

    return {
        container
    }
}