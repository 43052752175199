import { ref, computed, watch } from "vue"
import { useStore } from "vuex";
export default (props) => {
    const hover = ref(false);
    const store = useStore();


    const hoverStyle = ref({})
    const style = ref({})

    function generateStyles() {
        hoverStyle.value = props.context.getStyle(props.content.style, { hover: true });
        style.value = props.context.getStyle(props.content.style);
    }

    watch(store.state.data, () => {
        generateStyles();
    }, { flush: 'post' });

    generateStyles();

    return {
        hover, style: computed(() => {
            if (hover.value) {
                return hoverStyle.value;
            }
            return style.value;
        })
    };
}