<template>
  <popup :show="props.show" :close="props.close" :style="data.getStyle2(data.settings.header.menu.style_popup.style)">
    <router-menu :data="data" :styleLink="data.settings.header.menu.style_link_popup" :onClick="close" />
  </popup>
</template>
<script setup lang="ts">
import Data from "@/modules/data.js";
import RouterMenu from "./RouterMenu.vue";
import Popup from "./PopupComponent.vue";

const props = defineProps<{
  show: Boolean,
  close: Function,
}>()
const data = Data().data as any;
</script>
