<template>
  <router-link
    v-for="entry in data.settings.header.menu?.entries ?? []"
    :key="entry"
    :to="entry"
    custom
    v-slot="{ navigate, isActive }"
  >
    <styled-text
      v-if="data.pages[entry]"
      @click="nav(navigate)"
      :isActive="isActive"
      :text="data.getText(data.pages[entry].title)"
      :context="data"
      :content="styleLink"
    />
  </router-link>
</template>
<script>
export default {
  props: {
    data: Object,
    styleLink: Object,
    onClick: Function,
  },
  setup(props) {
    return {
      nav: (navigate) => {
        if (props.onClick) {
          props.onClick();
        }
        navigate();
      },
    };
  },
};
</script>