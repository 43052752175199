<template>
  <div v-if="props.content">
    <div v-if="props.content.type == 'foldable'">
      <ContentComponent @click="unfold" :content="props.content.header" :context="props.context" />
      <ExpandTransition :duration="500" :opacity-opened="1" :opacity-closed="0">
        <ContentComponent v-if="unfolded" :content="props.content.content" :context="props.context" />
      </ExpandTransition>
    </div>
    <ImageComponent v-else-if="props.content.type == 'image'" :content="props.content" :context="props.context" />
    <styled-text v-else-if="props.content.type == 'text'" :content="props.content" :context="props.context" />
    <VideoVue v-else-if="props.content.type == 'video'" :content="props.content" :context="props.context" />
    <AudioVue v-else-if="props.content.type == 'audio'" :content="props.content" :context="props.context" />
    <GalleryVue v-else-if="props.content.type == 'gallery'" :content="props.content" :context="props.context" />
    <TableVue v-else-if="props.content.type == 'table'" :content="props.content" :context="props.context" />
    <div v-else-if="props.content.type == 'list'" :style="props.context.getStyle(props.content.style)">
      <ContentComponent v-for="element in props.content.elements" :key="element" :content="element"
        :context="props.context" />
    </div>
  </div>
</template>
<script setup>
import GalleryVue from "@/components/dynamic/Gallery2.vue";
import ImageComponent from "@/components/dynamic/ImageComponent.vue";
import TableVue from "@/components/dynamic/Table.vue";
import VideoVue from "@/components/dynamic/Video.vue";
import AudioVue from "@/components/dynamic/Audio.vue";
import ExpandTransition from "@/components/ExpandTransition.vue";
import { ref } from "vue";

const props = defineProps({
  content: Object,
  context: Object,
})

const unfolded = ref(props.content.isExpanded ?? false);
function unfold() {
  unfolded.value = !unfolded.value;
}
</script>