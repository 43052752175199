//import json from "@/assets/data.json";
import { computed, ref } from "vue";

import { useStore } from "vuex";

export default () => {
    const store = useStore();

    const json = ref({ pages: {} });
    let copy = {}

    function getText(json) {
        if (!json) {
            return "UNDEFINED"
        }
        if (store.state.language == "de") {
            let tmp = json["de"] ?? json["en"];
            if (tmp == "") {
                return json["en"]
            } else {
                return tmp
            }
        } else {
            let tmp = json["en"] ?? json["de"];
            if (tmp == "") {
                return json["de"]
            } else {
                return tmp
            }
        }
    }

    function getImage(src) {
        console.log(src);
        return src + ".jpeg";
    }

    function getStyle(object, params = {}) {
        if (!object) {
            return {};
        }
        if (typeof object === 'string') {
            let tmp = copy.styles[object];
            if (params.hover) {
                tmp = { ...tmp, ...copy.styles[object + ":hover"] }
            }
            if (params.active) {
                tmp = { ...tmp, ...copy.styles[object + ":active"] }
            }
            return tmp;
        } else if (Array.isArray(object)) {
            let data = {};
            for (var obj of object) {
                data = { ...data, ...getStyle(obj) };
            }
            return data;
        }
        return object;
    }

    function getStyle2(object, params = {}) {
        if (!object) {
            return {};
        }
        if (typeof object === 'string') {
            if (params.hover) {
                return copy.styles[object + ":hover"];
            }
            if (params.active) {
                return copy.styles[object + ":active"];
            }
            return copy.styles[object];
        } else if (Array.isArray(object)) {
            let data = {};
            for (var obj of object) {
                data = { ...data, ...getStyle2(obj, params) };
            }
            return data;
        }
        return object;
    }

    return {
        data: computed(() => {
            json.value = store.state.data;
            let tmp;
            if (store.state.mq.vw < 100) {
                console.log("New ratio: " + store.state.mq.ratio);
                let ratioPx = store.state.mq.ratio;
                let ratioWv = store.state.mq.vw / 100;
                let ratioVh = store.state.mq.vh / 100;

                let stringified = JSON.stringify(json.value);
                stringified = stringified.replace(/[0-9]+(px|vw|vh)/g, function (match) {
                    let value = parseInt(match.substring(0, match.length - 2));
                    if (match.endsWith("px")) {
                        return value * ratioPx + "px"
                    } else if (match.endsWith("vw")) {
                        return value * ratioWv + "vw"
                    }
                    return value * ratioVh + "vh";
                });
                stringified = stringified.replace(/font-size":"[0-9]+%/g, function (match) {
                    let replace = match.replace(/[0-9]+%/g, function (match2) {
                        let value = parseInt(match2.substring(0, match2.length - 1));
                        return value * ratioPx + "%"
                    })
                    return replace;
                });
                tmp = JSON.parse(stringified);
            }

            if (navigator.userAgent.includes("Firefox")) {
                let stringified = JSON.stringify(tmp ? tmp : json.value);
                stringified = stringified.replaceAll("fit-content", "-moz-fit-content")
                tmp = JSON.parse(stringified);
            }

            copy = !tmp ? json.value : tmp;

            if (store.state.language == "de") {
                return { language: "de", ...copy, getText: getText, getStyle: getStyle, getStyle2: getStyle2, getImage: getImage }
            } else {
                return { language: "en", ...copy, getText: getText, getStyle: getStyle, getStyle2: getStyle2, getImage: getImage }
            }
        }),
    };
};
