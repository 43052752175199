<template>
  <div
    @mouseover="() => (hover = true)"
    @mousemove="() => (hover = true)"
    @mouseout="
      () => {
        hover = false;
        active = false;
      }
    "
    @mouseleave="
      () => {
        hover = false;
        active = false;
      }
    "
    @click="() => click($event)"
    @mousedown="() => (active = true)"
    @mouseup="
      () => {
        active = false;
        hover = false;
      }
    "
    :style="style"
    v-html="textToDisplay"
    style="display: inline-block"
  />
</template>
<script>
import { computed, ref, watch } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";

export default {
  props: {
    isActive: Boolean,
    text: String,
    context: Object,
    content: Object,
  },

  setup(props) {
    const store = useStore();
    const router = useRouter();

    const hover = ref(false);
    const active = ref(false);

    const hoverStyle = ref({});
    const activeStyle = ref({});
    const style = ref({});

    if (!props.content) {
      return {
        hover,
        active,
        textToDisplay: "Err[" + props.text + "]",
      };
    }

    function generateStyles() {
      hoverStyle.value = props.context.getStyle2(props.content.style, {
        hover: true,
      });
      activeStyle.value = props.context.getStyle2(props.content.style, {
        active: true,
      });
      style.value = props.context.getStyle2(props.content.style);
    }

    watch(
      store.state.data,
      () => {
        generateStyles();
      },
      { flush: "post" }
    );

    generateStyles();

    let textToDisplay = computed(() => {
      if (props.text) {
        return props.text;
      } else {
        return props.context.getText(props.content);
      }
    });

    return {
      active,
      hover,
      style: computed(() => {
        let baseStyle = style.value;
        if (props.isActive && activeStyle.value) {
          baseStyle = { ...baseStyle, ...activeStyle.value };
        }
        if (hover.value && hoverStyle.value) {
          baseStyle = { ...baseStyle, ...hoverStyle.value };
        }
        if (active.value && activeStyle.value) {
          baseStyle = { ...baseStyle, ...activeStyle.value };
        }
        return baseStyle;
      }),
      click: (event) => {
        let match = textToDisplay.value.match(/href="(\/.*)"/);
        if (match) {
          event.preventDefault();
          router.push(match[1]);
        }
      },
      textToDisplay,
    };
  },
};
</script>
<style scoped>
</style>