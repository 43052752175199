<template>
  <div class="container">
    <router-menu
      :data="data"
      :styleLink="data.settings.header.menu.style_link"
    />
  </div>
</template>
<script>
import Data from "@/modules/data.js";
import { useRoute } from "vue-router";
import RouterMenu from "./RouterMenu.vue";

export default {
  name: "MenueComponent",
  components: { RouterMenu },
  setup() {
    const { data } = Data();
    const route = useRoute();
    return {
      data,
      route,
    };
  },
};
</script>
<style scoped>
.container {
  text-align: center;
  display: flex;
  justify-content: center;
}
</style>