<template>
  <vueper-slides
    :fixed-height="$store.state.contentHeight + 'px'"
    :bullets="false"
    :touchable="false"
  >
    <vueper-slide v-for="(slide, i) in slides" :key="i">
      <template #content
        ><img
          draggable="false"
          :src="slide.image"
          :style="imageStyle"
          style="object-fit: contain; height: 100%"
      /></template>
    </vueper-slide>
  </vueper-slides>
</template>
<script>
// If you are using PurgeCSS, make sure to whitelist the carousel CSS classes
import { VueperSlides, VueperSlide } from "vueperslides";
import "vueperslides/dist/vueperslides.css";
import { computed } from "vue";
import { useStore } from "vuex";
import Image from "@/modules/image.js";

export default {
  name: "GalleryComponent",
  props: {
    content: Object,
    context: Object,
  },
  components: {
    VueperSlides,
    VueperSlide,
  },
  setup(props) {
    const store = useStore();
    const { getImage } = Image({ exactness: 0.2 });

    return {
      slides: computed(() => {
        let tmp = [];
        for (let img of props.content.images) {
          tmp.push({
            image: getImage(img, false, 1),
          });
        }
        return tmp;
      }),
      carouselHeight: computed(() => store.state.mq.vh + "vh"),
      imageStyle: computed(() => {
        return {
          "object-fit": "contain",
          height: store.state.mq.vh + "vh",
          width: store.state.mq.vw * 0.9 + "vw",
          "pointer-events": "none",
        };
      }),
      props,
    };
  },
};
</script>
<style>
.carousel__prev {
  left: 20px !important;
}

.carousel__next {
  right: 20px !important;
}

.carousel__prev,
.carousel__next {
  top: 50%;
  width: 30px;
  height: 30px;
  border-radius: 15px;
  background-color: rgba(255, 255, 255, 0.2);
  font-size: 32px;
  text-align: center;
}
</style>