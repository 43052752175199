<template>
  <div v-if="$store.state.admin" style="display: flex; flex-direction: row">
    <div style="
        z-index: 100;
        flex-basis: 50%;
        height: 100vh;
        background-color: black;
      " @click="disableAdmin">
      <AdminPanel />
    </div>
    <div :style="containerStyle">
      <div :style="innerContainerStyle" style="overflow-y: scroll;">
        <router-view />
      </div>
    </div>
  </div>
  <router-view v-else :style="innerContainerStyle" />
</template>
<script>
import { defineAsyncComponent, computed, onMounted } from "vue";
import { useStore } from "vuex";
const AdminPanel = defineAsyncComponent(() => import("@/admin/AdminPanel.vue"));
import { SERVER_URL } from "./constants";
export default {
  name: "AppPage",
  components: { AdminPanel },
  setup() {
    console.log(SERVER_URL);
    const store = useStore();

    onMounted(() => {
      store.dispatch("loadData");
    });

    return {
      innerContainerStyle: computed(() => {
        return {
          height: store.state.mq.vh + "vh",
          width: store.state.mq.vw + "vw",
        };
      }),
      containerStyle: computed(() => {
        if (store.state.admin) {
          return {
            "flex-basis": "50%",
          };
        } else {
          return { "flex-basis": "100%" };
        }
      }),
    };
  },
};
</script >
<style >
@import "./assets/styles.css";
</style>
