<template>
  <img
    :src="getImage(props.content.src)"
    class="background"
    :style="imgStyle"
  />
  <!-- <div :style="context.getStyle2(data.background_filter.style)" /> -->
</template>
<script>
import { computed } from "vue";
import { useStore } from "vuex";
import Image from "@/modules/image.js";
import Data from "@/modules/data.js";

export default {
  name: "ImageComponent",
  props: {
    content: Object,
    context: Object,
  },
  setup(props) {
    const store = useStore();
    const { getImage } = Image({ exactness: 0.2 });
    const { data } = Data();

    return {
      data,
      getImage,
      props,
      imgStyle: computed(() => {
        return {
          ...props.context.getStyle(props.content.style),
          left: store.state.mq.vwCenter + "vw",
        };
      }),
    };
  },
};
</script>
<style scoped>
</style>
