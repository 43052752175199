import { createStore } from "vuex";
import { SERVER_URL } from "../constants";


function uploadFile(commit, dispatch, file) {
    return new Promise((resolve) => {
        commit('setMediaUpload', { name: file.name, action: "loading", progress: 0 })
        const formData = new FormData();
        formData.append(file.name, file);

        let request = new XMLHttpRequest();
        request.open('POST', SERVER_URL + "/media/");
        request.withCredentials = true;

        request.upload.addEventListener('progress', function (e) {
            let percent_completed = (e.loaded / e.total) * 100;
            commit('setMediaUpload', { name: file.name, action: "loading", progress: percent_completed })
            console.log("Progress: " + file.name);
        });

        request.addEventListener('load', function () {
            commit('setMediaUpload', { name: file.name, action: "delete" })
            console.log("Finished: " + file.name);
            dispatch("loadMedia");
            resolve();
        });
        request.send(formData);
    });
}

async function serverAction(commit, fun) {
    commit("setServerTransmission", true);
    try {
        let res = await fun();
        if (!res.ok) {
            let errorMessage = "";
            try {
                errorMessage += "\n\n" + await res.text();
            } catch (err) {
                //noop
            }
            window.alert(res.status + " " + res.statusText + errorMessage);
        }
    } catch (err) {
        window.alert(err);
    } finally {
        commit("setServerTransmission", false);
    }
}

export default createStore({
    state() {
        let mq;
        if (localStorage.mq) {
            try {
                mq = JSON.parse(localStorage.mq);
            } catch (err) { //noop}
            }
        }
        if (!mq) {
            mq = { vw: 100, vh: 100, ratio: 1, vwCenter: 50, type: "desktop" }
        }
        return { serverTransmission: 0, languages: ["de", "en"], mediaUpload: {}, media: [], initialCss: "", css: "", login: {}, data: { pages: {}, settings: {} }, language: localStorage.language ?? "de", admin: JSON.parse(localStorage.admin ?? "false"), mq: mq, footerHeight: 0, headerHeight: 0, contentHeight: 0, editorData: null }
    },
    mutations: {
        changeLanguage(state, lang) {
            localStorage.language = lang;
            state.language = lang;
        },
        admin(state, isAdminActive) {
            localStorage.admin = isAdminActive;
            state.admin = isAdminActive;
            if (!isAdminActive) {
                state.login = {};
            }
        },
        viewport(state, data) {
            let vwCenter = 50;
            if (data.vw < 100) {
                vwCenter = 50 + data.vw / 2;
            }
            let tmp = { ...data, vwCenter: vwCenter };
            localStorage.mq = JSON.stringify(tmp);
            state.mq = tmp;
        },
        setData(state, data) {
            console.log("Got new data...");
            state.data = data;
        },
        initialData(state, data) {
            //console.log("Got initial data...");
            state.data = data;
            state.initialData = JSON.parse(JSON.stringify(data));
        },
        setCss(state, data) {
            console.log("Got new css...");
            state.css = data;
        },
        initialCss(state, data) {
            state.css = data;
            state.initialCss = data;
        },
        setLogin(state, data) {
            state.login = data;
            if (data.username) {
                localStorage.login = JSON.stringify(data);
            }
        },
        setMedia(state, data) {
            console.log("Got media...");
            state.media = data;
        },
        setMediaUpload(state, data) {
            if (data.action == 'delete') {
                delete state.mediaUpload[data.name];
            } else {
                state.mediaUpload[data.name] = { action: data.action, progress: data.progress };
                console.log(JSON.stringify(state.mediaUpload));
            }
        },
        setServerTransmission(state, isTransmitting) {
            state.serverTransmission += isTransmitting ? 1 : -1
        },
        resetData() {
            location.reload()
        }
    },
    actions: {
        async loadData({ commit }) {
            serverAction(commit, async () => {
                const res = await fetch(SERVER_URL + "/data", { credentials: 'include' });
                commit("initialData", await res.json());
                return res;
            });
        },
        async storeData({ commit }, data) {
            serverAction(commit, async () => {
                const res = await fetch(SERVER_URL + "/data",
                    {
                        body: JSON.stringify(data),
                        headers: { 'content-type': "application/json" },
                        method: "POST",
                        credentials: 'include'
                    });
                if (res.ok) {
                    commit("initialData", data);
                }
                return res;
            });
        },
        async storeCss({ commit }, data) {
            serverAction(commit, async () => {
                const res = await fetch(SERVER_URL + "/data/css",
                    {
                        body: data,
                        headers: { 'content-type': "text/css" },
                        method: "POST",
                        credentials: 'include'
                    });
                if (res.ok) {
                    commit("initialCss", data);
                }
                return res;
            });
        },
        async login({ commit }, data) {
            commit("setLogin", { loading: true });
            const res = await fetch(SERVER_URL + "/auth", { method: "POST", headers: { 'Authorization': 'Basic ' + btoa(data.username + ":" + data.password) }, credentials: 'include' });
            try {
                if (res.ok) {
                    commit("setLogin", { loggedIn: true });
                    return;
                }
            } catch (err) {
                //noop
            }
            commit("setLogin", { error: true });
        },
        async logout({ commit }, fromApi = true) {
            try {
                if (fromApi == true) {
                    console.log("Logout");
                    await fetch(SERVER_URL + "/auth", { method: "DELETE", credentials: 'include' });
                }
            } catch (err) {
                //noop
            } finally {
                commit("setLogin", {});
                commit("admin", false);
                commit("viewport", {
                    vw: 100,
                    vh: 100,
                    ratio: 1,
                    type: "default",
                });
                commit("resetData");
            }
        },
        async tryLogin({ commit }) {
            commit("setLogin", { loading: true });
            const res = await fetch(SERVER_URL + "/auth", { method: "POST", credentials: 'include' });
            try {
                if (res.ok) {
                    commit("setLogin", { loggedIn: true });
                    return;
                }
            } catch (err) {
                //noop
            }
            commit("setLogin", {});
        },
        async loadMedia({ commit }) {
            console.log("Loading media...");
            const res = await fetch(SERVER_URL + "/media", { credentials: 'include' });
            try {
                if (res.ok) {
                    commit("setMedia", await res.json());
                    return;
                }
            } catch (err) {
                //noop
            }
        },
        async loadCss({ commit }) {
            serverAction(commit, async () => {
                console.log("Loading css...");
                const res = await fetch(SERVER_URL + "/data/css", { credentials: 'include' });
                if (res.ok) {
                    commit("initialCss", await res.text());
                }
                return res;
            });
        },
        async deleteMedia({ dispatch, commit }, data) {
            console.log("Deleting media..." + data);
            const res = await fetch(SERVER_URL + "/media/" + data, { method: "DELETE", credentials: 'include' });
            try {
                if (res.ok) {
                    console.log("OK");
                    return;
                }
            } catch (err) {
                //noop
            } finally {
                dispatch("loadMedia")
            }
            console.log("" + commit);
            console.log("Failed...");
        },
        async uploadMedia({ dispatch, commit }, files) {
            let promises = []
            for (let file of files) {
                promises.push(uploadFile(commit, dispatch, file));
            }
            await Promise.all(promises);
        }
    }
});
